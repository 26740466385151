<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Spinner from "vue-simple-spinner";
import * as cloneDeep from "lodash.clonedeep";
import Swal from "sweetalert2";
import { required, email } from 'vuelidate/lib/validators';
export const CHAIPAY_APP_URL = process.env.VUE_APP_CHAIPAY_FRONTEND_URL;

import { paymentMethods } from "@/state/helpers";
import {
  FETCH_MERCHANTS_PAGINATED_LIST,
  FETCH_INVITED_MERCHANTS_LIST,
  ACTIVE_INACTIVE_MERCHANT,
  INVITE_MERCHANT,
  MERCHANT_RESEND_KYC,
  MERCHANT_RESEND_INVITE,
  DELETE_MERCHANT
} from "@/state/actions.type";
import Common from "../../../helpers/Common";

export default {
  components: {
    Layout,
    PageHeader,
    Spinner,
  },
  data() {
    return {
      merchant_name:"",
      merchant_mail_id:"",
      merchant_country: "VN",
      countryList: Common.countryList(),
      // merchant_phone_number:"",
      showInviteMerchantmodal:false,
      // AccountStatus:"",
      isInviteSend:false,
      AccountType:"MANAGED",
      isLoginAllowed: true,
      isInviteMerchant: false,
      useMasterMerchantCreds: false,
      sendKycLinkEmail: false,
      merchantListData: [],
      invitedmerchantListData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      mor_filter: "",
      filter: {},
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      showOptions: false,
      fields: [
        { key: "status", sortable: true, label: "views.master_merchant.table.fields.status" },
        { key: "name", sortable: true, label: "views.master_merchant.table.fields.name" },
        { key: "email_address", sortable: true, label: "views.master_merchant.table.fields.email_id" },
        // { key: "ph_number", sortable: true, label: "Phone Number" },
        // { key: "is_invite_merchant", sortable: false, label: "views.master_merchant.table.fields.invite_merchant" },
        { key: "is_login_allowed", sortable: false, label: "views.master_merchant.table.fields.login_allowed" },
        { key: "use_master_merchant_creds", sortable: false, label: "views.master_merchant.table.fields.use_mm_creds" },
        { key: "key", sortable: false, label: "views.master_merchant.table.fields.key" },
        { key: "kyc_link", sortable: false, label: "views.master_merchant.table.fields.kyc_link" },
        { key: "kyc_status", sortable: false, label: "views.master_merchant.table.fields.kyc_status" },
        { key: "resend_kyc", sortable: false, label: "views.master_merchant.table.fields.resend_kyc_link" },
        { key: "acc_status", sortable: false, label: "views.master_merchant.table.fields.account_status" },
        { key: "delete_merchant", sortable: false, label: "views.master_merchant.table.fields.delete" },
      ],
      invitedfields: [
        { key: "status", sortable: true, label: "views.master_merchant.table.fields.status" },
        { key: "email_id", sortable: true, label: "views.master_merchant.table.fields.email_id" },
        { key: "created_at", sortable: true, label: "views.master_merchant.table.fields.date" },
        { key: "invite_link", sortable: true, label: "Invite link" },
        { key: "resend_invite_email", sortable: false, label: "Resend Invite Email" },
      ],
      submitted: false
    };
  },
  validations: {
    merchant_name: {
      required
    },
    merchant_mail_id: {
      required,
      email
    }
  },
  created() {
    this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_PAGINATED_LIST}`, {
      pageIndex: 1,
      pagesize: 10,
      filters: this.filter,
    });
    this.merchantListData = cloneDeep(this.merchantListDataFromStore);
    this.$store.dispatch(`masterMerchant/${FETCH_INVITED_MERCHANTS_LIST}`);
    this.invitedmerchantListData = cloneDeep(this.invitedmerchantListDataFromStore);
  },

  computed: {
    isValid() {
      if (this.merchant_name && this.merchant_mail_id) {
        return true;
      } else {
        return false;
      }
    },
    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.paginatedContent;
    },

    invitedmerchantListDataFromStore() {
      return this.$store.state.masterMerchant.invitedContent;
    },

    isFetchingTransactions() {
      return this.$store.state.masterMerchant.isFetchingData;
    },
    
    rows() {
      return this.$store.state.masterMerchant.total_elements;
    },

    items() {
      return [
        {
          text: "PortOne",
          active: true,
        },
        {
          text: this.$t('menuitems.master_merchant.text'),
          active: false,
        },
      ];
    }
  },
  watch: {
    perPage: function (val) {
      this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_PAGINATED_LIST}`, {
        pageIndex: this.currentPage,
        pagesize: val,
        filters: this.filter
      });
    },

    currentPage: function (val) {
      this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_PAGINATED_LIST}`, {
        pageIndex: val,
        pagesize: this.perPage,
        filters: this.filter,
      });
    },

    merchantListDataFromStore() {
      this.merchantListData = cloneDeep(this.merchantListDataFromStore);
    },

    invitedmerchantListDataFromStore() {
      this.invitedmerchantListData = cloneDeep(this.invitedmerchantListDataFromStore);
    },
  },

  mounted() {
    // Set the initial number of items
    this.merchantListData = cloneDeep(this.merchantListDataFromStore);
    this.invitedmerchantListData = cloneDeep(this.invitedmerchantListDataFromStore);
    this.totalRows = this.merchantListData.length;
  },

  destroyed() {
    // document.body.classList.remove("dynamic-body");
  },

  methods: {
    ...paymentMethods,
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    sortByDate(a, b, key) {
      if (key == "date") {
        const d1 = new Date(a[key]).getTime();
        const d2 = new Date(b[key]).getTime();
        return d1 - d2;
      }
    },

    filterTransactionByMor() {
      if (this.mor_filter == "") {
        this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_PAGINATED_LIST}`, {
          pageIndex: 1,
          pagesize: 10,
          filters: this.filter,
        });
      } else {
        this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_PAGINATED_LIST}`, {
          pageIndex: 1,
          pagesize: 10,
          filters: { source: "default", merchant_order_ref: this.mor_filter },
        });
      }
    },
    InviteMerchant() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.isInviteSend = true;
      this.submitted = false;
      this.$store.dispatch(`masterMerchant/${INVITE_MERCHANT}`,{
        email_address: this.merchant_mail_id,
        name:this.merchant_name,
        country: this.merchant_country,
        is_login_allowed: this.isLoginAllowed,
        is_invite_merchant: this.isLoginAllowed,
        use_master_merchant_creds: this.useMasterMerchantCreds,
        send_kyc_link_email: this.sendKycLinkEmail
      }).then(() => {
        this.isInviteSend = false;
        this.showInviteMerchantmodal = false;
        this.$notify({
          type: "success",
          text: this.$t('views.master_merchant.merchant_added_success'),
          closeOnClick: true,
        });
        this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_PAGINATED_LIST}`, {
          pageIndex: 1,
          pagesize: 10,
          filters: this.filter,
        });
        this.$store.dispatch(`masterMerchant/${FETCH_INVITED_MERCHANTS_LIST}`);
      })
      .catch((error) => {
        // error
        this.showInviteMerchantmodal = false;
        const errorObject = JSON.parse(error.message)
        console.log("error", error);
        this.isInviteSend = false;
        this.$notify({
          type: "error",
          text: errorObject.message,
          closeOnClick: true,
        });
      });

      // if(this.AccountType == "MANAGED"){
      //  this.$store.dispatch(`masterMerchant/${INVITE_MERCHANT_FOR_MANAGED}`,{
      //     email: this.merchant_mail_id,
      //     merchant_type:this.AccountType
      //   }).then(() => {
      //     this.isInviteSend = false;
      //     this.showInviteMerchantmodal = false;
      //     this.$notify({
      //       type: "success",
      //       text: "Merchant Invited Successfully.",
      //       closeOnClick: true,
      //     });
      //     this.$store.dispatch(`masterMerchant/${FETCH_INVITED_MERCHANTS_LIST}`);
      //     this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_PAGINATED_LIST}`, {
      //       pageIndex: 1,
      //       pagesize: 10,
      //       filters: this.filter,
      //     });
      //   })
      //   .catch((error) => {
      //     // error
      //     this.showInviteMerchantmodal = false;
      //     console.log("error", error);
      //   });
      // }else{
      //     this.$store.dispatch(`masterMerchant/${INVITE_MERCHANT_FOR_OWNED}`,{
      //       email: this.merchant_mail_id,
      //       merchant_type:this.AccountType,
      //       name:this.merchant_name,
      //     }).then(() => {
      //       this.isInviteSend = false;
      //       this.showInviteMerchantmodal = false;
      //       this.$notify({
      //         type: "success",
      //         text: "Merchant Added Successfully.",
      //         closeOnClick: true,
      //       });
      //       this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_PAGINATED_LIST}`, {
      //         pageIndex: 1,
      //         pagesize: 10,
      //         filters: this.filter,
      //       });
      //     })
      //     .catch((error) => {
      //       // error
      //       this.showInviteMerchantmodal = false;
      //       console.log("error", error);
      //     });
      // }
    },
    fetchTransactionOnEmptyFilter() {
      if (this.mor_filter == "") {
        this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_PAGINATED_LIST}`, {
          pageIndex: 1,
          pagesize: 10,
          filters: this.filter,
        });
      }
    },
    openInviteMerchantModal() {
      this.merchant_name = ""
      this.merchant_mail_id = ""
      this.isLoginAllowed = true
      this.isInviteMerchant = false
      this.useMasterMerchantCreds = false
      this.sendKycLinkEmail = false
      // this.merchant_phone_number = ""
      // this.AccountStatus = ""
      this.AccountType = "MANAGED"
      this.showInviteMerchantmodal = true;
    },

    hideInviteMerchantModal() {
      this.showInviteMerchantmodal = false;
    },
    markasActiveandInactive(val,uuid) {
      this.$store.dispatch(`masterMerchant/${ACTIVE_INACTIVE_MERCHANT}`, {
        status: val,
        uuid:uuid
      }).then(() => {
        this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_PAGINATED_LIST}`, {
          pageIndex: this.currentPage,
          pagesize: this.perPage,
          filters: {},
        })
      });
    },
    onCopySuccess() {
      this.$notify({
        type: "success",
        text: this.$t('views.master_merchant.kyc_link_copy_success'),
        closeOnClick: true,
      });
    },
    getRegionName(countryCode) {
      let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
      return regionNames.of(countryCode);
    },
    resendKYC(item) {
      this.$store.dispatch(`masterMerchant/${MERCHANT_RESEND_KYC}`, {
        iamportKey: item.key
      }).then(() => {
        this.$notify({
          type: "success",
          text: this.$t('views.master_merchant.kyc_link_sent_success'),
          closeOnClick: true,
        });
      });
    },
    resendInvite(item) {
      this.$store.dispatch(`masterMerchant/${MERCHANT_RESEND_INVITE}`, {
        email: item.email_id
      }).then(() => {
        this.$notify({
          type: "success",
          text: "Invite Link sent successfully.",
          closeOnClick: true,
        });
      });
    },
    deleteMerchant(item) {
      Swal.fire({
        title: this.$t("common.sure"),
        text: this.$t("common.unrevertable"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("common.delete"),
        cancelButtonText: this.$t('button.cancel'),
      }).then(result => {
        if (result.value) {
          this.$store.dispatch(`masterMerchant/${DELETE_MERCHANT}`, {
            merchantKey: item.key
          })
          .then((response) => {
            console.log(response);
            this.$notify({
              type: "success",
              text: this.$t("views.master_merchant.merchant_delete_success"),
              closeOnClick: true,
            });
          })
          .catch((error) => {
            // error
            console.log("error", error);
            Swal.fire(this.$t("common.deleted"), this.$t("views.master_merchant.master_merchant_delete_failed"), "error");
          })
          .finally(() => {
            this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_PAGINATED_LIST}`, {
              pageIndex: 1,
              pagesize: 10,
              filters: this.filter,
            });
            this.merchantListData = cloneDeep(this.merchantListDataFromStore);
          });
        }
      });
    },
    toggleOptions() {
      this.showOptions = !this.showOptions;
    }
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },
};
</script>

<template>
  <Layout>
    <PageHeader title="Master Merchant" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="row mt-4">
          <div class="col-sm-12 col-md-12 d-flex justify-content-between">
            <div id="tickets-table_length" class="dataTables_length">
              <label style="font-size: 13px;" class="d-inline-flex align-items-center">
                {{ $t('common.show') }}&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  style="border: 1px solid #EAEAEA;"
                ></b-form-select>
                {{ $t('common.entries') }}
              </label>
              <!-- <b-input-group class="d-inline-flex align-items-center input-group col-lg-5">
                <b-form-input
                  type="search"
                  v-model="mor_filter"
                  @keyup="fetchTransactionOnEmptyFilter"
                  @keyup.enter="filterTransactionByMor"
                  class="form-control form-control-sm ml-2"
                  placeholder="Search"
                  style="border: 1px solid #EAEAEA;"
                ></b-form-input>
                <b-input-group-append>
                  <button
                    class="btn btn-primary btn-block btn-sm search-button"
                    @click="filterTransactionByMor"
                  >
                    {{ $t("views.payments.transactions.search") }}
                  </button>
                </b-input-group-append>
              </b-input-group> -->
            </div>
            <div>
              <b-btn
                size="sm"
                variant="primary"
                @click="openInviteMerchantModal"
              >
                {{ $t('views.master_merchant.add_merchant') }}
              </b-btn>
            </div>
          </div>
        </div>

        <div class="">
          <b-table
            class="table-centered text-center table-custom"
            :items="merchantListData"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-compare="sortByDate"
            :bordered="false"
            :small="true"
            :filter-included-fields="filterOn"
            sticky-header="400px"
            show-empty
            :empty-text="$t('common.no-records')"
            busy.sync="true"
            hover
          >
          <template v-slot:head()="data">
            {{ $t(data.label) }}
          </template>
          <template v-slot:cell(status)="row">
              <div
                class="badge font-size-12"
                :class="{
                  'badge-soft-danger':`${row.value}` === 'INACTIVE',
                  'badge-soft-success': `${row.value}` === 'ACTIVE',
                }"
              >
                {{ row.value }}
              </div>
            </template>
            <template v-slot:cell(is_login_allowed)="row">
              <img src="@/assets/images/check-circle.svg" v-if="row.value">
              <img src="@/assets/images/x-circle.svg" v-else>
            </template>
            <template v-slot:cell(use_master_merchant_creds)="row">
              <img src="@/assets/images/check-circle.svg" v-if="row.value">
              <img src="@/assets/images/x-circle.svg" v-else>
            </template>
            <template v-slot:cell(kyc_link)="row">
              <a v-show="row.value" target="_black" :href="row.value">
                {{ $t("views.master_merchant.view_link") }}</a
              >
              <span v-show="!row.value">{{ $t("common.na") }}</span>
              <i
                v-show="row.value"
                v-clipboard:copy="row.value"
                v-clipboard:success="onCopySuccess"
                class="mdi mdi-content-copy btn-lg btn"
                :title="$t('common.copy')"
                v-b-tooltip.hover.right
              ></i>
            </template>
            <template v-slot:cell(kyc_status)="row">
              <div
                class="badge font-size-12"
                :class="{
                    'badge-soft-danger':`${row.value}` === 'PENDING',
                    'badge-soft-warning': `${row.value}` === 'SUBMITTED',
                    'badge-soft-success': `${row.value}` === 'DONE',
                }"
                >
                {{ row.value }}
              </div>
            </template>
            <template v-slot:cell(resend_kyc)="row">
              <span v-if="!['SUBMITTED', 'DONE'].includes(row.item.kyc_status)">
                <b-btn size="sm" v-if="row.item.send_kyc_link_email" @click="resendKYC(row.item)" variant="primary">{{ $t('views.master_merchant.resend_kyc') }}</b-btn>
                <b-btn size="sm" v-else @click="resendKYC(row.item)" variant="primary">{{ $t('views.master_merchant.send_kyc') }}</b-btn>
              </span>
            </template>
            <template v-slot:cell(acc_status)="row">
              <button
                href="javascript:void(0);"
                class="btn btn-primary px-1 py-1"
                v-if="row.value == 'ACTIVE'"
                style="font-size: 12px; border: 1px solid #252B3A; color: #252B3A; background: #fff; width:95px;"
                @click="markasActiveandInactive('INACTIVE',row.item.key)"
              >
                <div class="checkbox-group">
                  <label class="filled position-relative mb-0">{{ $t('views.master_merchant.enabled') }}</label>
                </div>
              </button>
              <button
                href="javascript:void(0);"
                class="btn btn-primary px-1 py-1"
                v-if="row.value == 'INACTIVE'"
                style="font-size: 12px; border: 1px solid #DAD9D9; color: #D9D9D9; background: #fff; width:95px;"
                @click="markasActiveandInactive('ACTIVE',row.item.key)"
              >
                <div class="checkbox-group">
                  <label class="position-relative mb-0">{{ $t('views.master_merchant.disabled') }}</label>
                </div>
              </button>
            </template>
            <template v-slot:cell(delete_merchant)="row">
              <b-btn size="sm" @click="deleteMerchant(row.item)" variant="danger">{{ $t("button.delete") }}</b-btn>
            </template>
          </b-table>
          <Spinner v-if="isFetchingTransactions === true"></Spinner>
        </div>
        <div class="row">
          <div class="col">
            <div
              class="dataTables_paginate paging_simple_numbers float-right"
              style="padding-bottom: 20px;"
            >
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :current-page="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
            <div class="clear-both"></div>
          </div>
        </div>
        <!-- </b-tab> -->
        <div data-v-61538448="" style="color: rgb(37, 43, 59); padding-bottom: 15px;">{{ $t('views.master_merchant.invited_merchant') }}</div>
        <div class="table-responsive">
          <b-table
            class="table-centered text-center table-custom"
            :items="invitedmerchantListData"
            :fields="invitedfields"
            responsive="sm"
            :bordered="false"
            :small="true"
            sticky-header="400px"
            show-empty
            :empty-text="$t('common.no-records')"
            busy.sync="true"
            hover
          >
            <template v-slot:head()="data">
              {{ $t(data.label) }}
            </template>
            <template v-slot:cell(status)="row">
              <div
                class="badge font-size-12"
                :class="{
                  'badge-soft-danger':`${row.value}` === 'INACTIVE',
                  'badge-soft-success': `${row.value}` === 'ACTIVE',
                  'badge-soft-info': `${row.value}` === 'INVITED'
                }"
              >
                {{ row.value }}
              </div>
            </template>
            <template v-slot:cell(invite_link)="row">
              <a v-show="row.value" target="_black" :href="row.value">
                {{ $t("View Link") }}</a
              >
              <span v-show="!row.value"> NA</span>
              <i
                v-show="row.value"
                v-clipboard:copy="row.value"
                v-clipboard:success="onCopySuccess"
                class="mdi mdi-content-copy btn-lg btn"
                title="Copy"
                v-b-tooltip.hover.right
              ></i>
            </template>
            <template v-slot:cell(resend_invite_email)="row">
              <b-btn size="sm" @click="resendInvite(row.item)" variant="primary">Resend Invite</b-btn>
              <!-- <b-btn size="sm" @click="resendInvite(row.item)" variant="primary">Send KYC</b-btn> -->
            </template>
          </b-table>
          <Spinner v-if="isFetchingTransactions === true"></Spinner>
        </div>
            <!-- </b-tabs> -->
      </div>
    </div>
    <div>
        <!-- Right Sidebar -->
        <div class="right-bar"
        :class="{
            'active': showInviteMerchantmodal
          }">
          <img src="@/assets/images/update_config_bottom.png" class="right-bottom-bg-image" alt />
          <div data-simplebar class="h-100">
            <div class="rightbar-title pb-3">
              <div class="right-top-section">
                <img src="@/assets/images/update_config_page.png" class="right-top-bg-image" alt />
                <div class="card-body-custom">
                  <i class="mdi mdi-close noti-icon float-right cursor-pointer" @click="hideInviteMerchantModal"></i>
                  <div class="media-body media-body-custom d-inline-block">
                    <p class="d-inline-block mb-0 mt-4 font-size-15">{{ $t('views.master_merchant.invite_merchant') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-3">
              <form id="modal" action="#" @submit.prevent="InviteMerchant">
                <!-- <div class="form-group font-size-12">
                  <div
                    class="btn btn-primary ml-2 px-1 py-1" id="managed-merchant-cont"
                    style="font-size: 12px; border: 1px solid #252B3A; color: #252B3A; background: #fff; width:115px;"
                  >
                    <div class="checkbox-group">
                      <input type="radio" id="MANAGED" value="MANAGED" v-model="AccountType">
                      <label for="MANAGED" class="position-relative mb-0"
                      :class="{
                          'filled': AccountType == 'MANAGED'
                        }">{{ $t('views.master_merchant.managed') }}</label>
                    </div>
                  </div>
                  <b-tooltip target="managed-merchant-cont">
                    <p>
                      {{
                        $t('views.master_merchant.managed_tooltip')
                      }}
                    </p>
                  </b-tooltip>
                   <div
                    class="btn btn-primary ml-2 px-1 py-1" id="owned-merchant-cont"
                    style="font-size: 12px; border: 1px solid #252B3A; color: #252B3A; background: #fff; width:115px;"
                  >
                    <div class="checkbox-group">
                      <input type="radio" id="OWNED" value="OWNED" v-model="AccountType">
                      <label for="OWNED" class="position-relative mb-0"
                      :class="{
                          'filled': AccountType == 'OWNED'
                        }">{{ $t('views.master_merchant.owned') }}</label>
                    </div>
                   </div>
                   <b-tooltip target="owned-merchant-cont">
                    <p>
                      {{
                        $t('views.master_merchant.owned_tooltip')
                      }}
                    </p>
                  </b-tooltip>
                </div> -->
                <div
                  class="form-group"
                >
                  <input
                    id="name"
                    v-model="merchant_name"
                    type="text"
                    class="form-control"
                    :placeholder="$t('views.master_merchant.placeholder.name')"
                    name="name"
                  />
                  <div class="error" style="font-size: 80%;" v-if="submitted && !$v.merchant_name.required"></div>
                </div>
                <div
                  class="form-group"
                >
                  <input
                    id="email_id"
                    v-model="merchant_mail_id"
                    type="text"
                    class="form-control"
                    :placeholder="$t('views.master_merchant.placeholder.email')"
                    name="email_id"
                  />
                  <div class="error" style="font-size: 80%" v-if="submitted && !$v.merchant_mail_id.required">{{ $t('views.master_merchant.validation.name') }}</div>
                  <div class="error" style="font-size: 80%" v-if="submitted && !$v.merchant_mail_id.email">{{ $t('views.master_merchant.validation.email') }}</div>
                </div>
                <div class="form-group auth-form-group-custom mb-4">
                  <i
                    class="ri-parent-line auti-custom-input-icon"
                  ></i>
                  <label for="country">Country</label>

                  <b-form-select name="country" v-model="merchant_country" class="form-control">

                    <b-form-select-option v-for="country in countryList" :key="country" :value="country">{{ getRegionName(country) }}</b-form-select-option>
                  </b-form-select>

                </div>
                <div @click="toggleOptions" style="cursor: pointer;" class="d-flex justify-content-between">
                  <label style="cursor: pointer;">Advanced Options</label>
                  <span>
                    <b-icon icon="chevron-up" v-show="showOptions"></b-icon>
                    <b-icon icon="chevron-down" v-show="!showOptions"></b-icon>
                  </span>
                </div>
                <div v-show="showOptions">
                  <div class="my-3">
                    <div
                      class="btn btn-primary ml-1 px-1 py-1 row" id="is-login-allowed"
                      style="font-size: 12px; border: 1px solid #252B3A; color: #252B3A; background: #fff; width:115px;"
                    >
                      <div class="checkbox-group">
                        <input type="radio" id="LOGIN_ALLOWED" v-model="isLoginAllowed" :value="true" :checked="isLoginAllowed" @click="() => isLoginAllowed = !isLoginAllowed">
                        <label for="LOGIN_ALLOWED" class="position-relative mb-0"
                        :class="{
                            'filled': isLoginAllowed
                          }">{{ $t('views.master_merchant.login_allowed') }}</label>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="my-3">
                    <div
                      class="btn btn-primary ml-1 px-1 py-1" id="invite-merchant"
                      style="font-size: 12px; border: 1px solid #252B3A; color: #252B3A; background: #fff; width:130px;"
                    >
                      <div class="checkbox-group">
                        <input type="radio" id="INVITE_MERCHANT" v-model="isInviteMerchant" value="true" :checked="isInviteMerchant" @click="() => isInviteMerchant = !isInviteMerchant">
                        <label for="INVITE_MERCHANT" class="position-relative mb-0"
                        :class="{
                            'filled': isInviteMerchant
                          }">Invite Merchant</label>
                      </div>
                    </div>
                  </div> -->
                  <div class="my-3">
                    <div
                      class="btn btn-primary ml-1 px-1 py-1" id="use-master-merchant-creds"
                      style="font-size: 12px; border: 1px solid #252B3A; color: #252B3A; background: #fff; width:196px;"
                    >
                      <div class="checkbox-group">
                        <input type="radio" id="USE_MASTER_MERCHANT_CREDS" v-model="useMasterMerchantCreds" :value="true" :checked="useMasterMerchantCreds" @click="() => useMasterMerchantCreds = !useMasterMerchantCreds">
                        <label for="USE_MASTER_MERCHANT_CREDS" class="position-relative mb-0"
                        :class="{
                            'filled': useMasterMerchantCreds
                          }">{{ $t('views.master_merchant.use_mm_creds') }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="my-3">
                    <div
                      class="btn btn-primary ml-1 px-1 py-1 row" id="is-login-allowed"
                      style="font-size: 12px; border: 1px solid #252B3A; color: #252B3A; background: #fff; width:155px;"
                    >
                      <div class="checkbox-group">
                        <input type="radio" id="SEND_KYC_LINK_EMAIL" v-model="sendKycLinkEmail" :value="true" :checked="sendKycLinkEmail" @click="() => sendKycLinkEmail = !sendKycLinkEmail">
                        <label for="SEND_KYC_LINK_EMAIL" class="position-relative mb-0"
                        :class="{
                            'filled': sendKycLinkEmail
                          }">{{ $t('views.master_merchant.send_kyc_link') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div
                  class="form-group"
                >
                  <input
                    id="phone_number"
                    v-model="merchant_phone_number"
                    type="text"
                    class="form-control"
                    :placeholder="`Enter Phone Number`"
                    name="phone_number"
                  />
                </div> -->
                <!-- <div class="form-group text-right mt-4 font-size-12">
                  <span >Account Status</span>
                   <div
                    class="btn btn-primary ml-2 px-1 py-1"
                    style="font-size: 12px; border: 1px solid #252B3A; color: #252B3A; background: #fff; width:95px;"
                  >
                    <div class="checkbox-group">
                      <input type="checkbox" id="filter_vietnam" value="true" v-model="AccountStatus">
                      <label for="filter_vietnam" class="position-relative mb-0"
                      :class="{
                          'filled': AccountStatus
                        }">Activate</label>
                    </div>
                   </div>
                </div> -->
                <div class="text-left mt-5">
                  <button
                    type="submit"
                    class="btn btn-success dark-bg ml-1 col-4"
                    :disabled="!isValid"
                  >
                    {{ $t('button.save') }}
                  </button>
                  <b-button class="ml-2 col-4" variant="light" @click="hideInviteMerchantModal"
                    >{{ $t('button.cancel') }}</b-button>
                </div>
                <!-- <div class="mt-4">
                  <div style="font-size: 13px;">Owned Merchant will be added immediately.</div>
                  <div style="font-size: 13px;">Managed Merchant will be invited.</div>
                </div> -->
              </form>
            </div>
          </div>
          <!-- end slimscroll-menu-->
        </div>
        <!-- /Right-bar -->
        <!-- Right bar overlay-->
        <div @click="hideInviteMerchantModal" class="rightbar-overlay"
        :class="{
            'active': showInviteMerchantmodal
        }"></div>
        <div v-if="isInviteSend === true" style="background-color: rgba(52, 58, 64, 0.55);position: fixed;left: 0;right: 0;top: 0;bottom: 0;z-index: 9998;transition: all 0.2s ease-out;">
          <div style="position: absolute;top: 50%;left: 50%;transform: translateY(-50%);">
            <Spinner></Spinner>
          </div>
        </div> 
      </div>
  </Layout>
</template>

<style lang="css" scoped>
::v-deep .table > tbody > tr > td {
  white-space: nowrap;
  background: #fff;
  border: none;
  border-bottom: 1px solid #eff2f7;
  padding-top: 12px;
  padding-bottom: 12px;    
  font-size: 13px;
}

::v-deep .table > thead > tr > th {
  white-space: nowrap;
  font-size: medium;
}

::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}
.media-body-custom{
  font-size: 12px;
  position: relative;
}
.media-body-custom img{
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 10px;
  bottom:2px;
}
.btn-custom{
  border-radius: 4px;
  color: #FFFCFC;
  background-color: #252b3a;
  border-color: #252b3a;
  padding-left: 15px;
  padding-right: 15px;
}
.btn-custom.disabled{
  pointer-events: none;
}
.search-button{
  background: #EAEAEA;
  border-color: #EAEAEA;
  color: #B3AFAF;
}
::v-deep .main-content {
    overflow: visible;
}
.card-body-custom{
  padding:15px 20px;
  position: relative;
}
.dark-bg{
  background-color: #252B3A;
  border-color: #252B3A;
}
.right-top-section{
  /* border-bottom: 1px solid #D9D9D9; */
  background-color: #F7F7F7;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  position: relative;
  z-index: 1;
  height: 130px;
}
.right-bar{
  width: 25%;
  right: -25%;
  z-index: 1010;
}
.right-bar.active{
  right: 0px;
}
.rightbar-overlay{
  z-index: 1009;
}
.rightbar-overlay.active{
  display: block;
  position: fixed;
}
.rightbar-title{
  color: #252B3B;
}
.right-top-bg-image{
    position: absolute;
    right: 0px;
}
.right-bottom-bg-image{
  position: absolute;
  bottom: 0px;
}
.cursor-pointer{
  cursor: pointer;
}
.checkbox-group{
  position: relative;
}
.checkbox-group input{
  display: none;
}
.checkbox-group  label {
  cursor: pointer;
}
.checkbox-group{
  display: inline-block;
}
.checkbox-group  label {
  cursor: pointer;
}
.checkbox-group  label:before {
  content:'';
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #DAD9D9;
  border-radius: 4px;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  bottom:1px;
}
.checkbox-group  label.filled:before {
  background-color: #252B3A;
  border: 1px solid #252B3A;
}

.checkbox-group label.filled:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 7px;
  width: 4px;
  height: 9px;
  border: 1px solid #fff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
@media (max-width: 1300px) {
  .right-bar{
    width: 360px;
    right: -360px;
  }
}
@media (max-width: 400px) {
  .right-bar{
    width: 320px;
    right: -320px;
  }
}
::v-deep .btn{
  border-radius: 4px;
}
::v-deep .form-control,v-deep .form-group {
  font-size: 14px;
}
::v-deep label{
  font-size: 14px;
}
::v-deep .custom-datepicker .xmx-input{
  height: 44px !important;
  padding-top: 4px;
  min-height: unset;
  border: 1px solid #DCDAD5;
  box-shadow: none;
  border: 1px solid #DCDAD5;
  border-radius: 12px;
}
</style>